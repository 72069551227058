import colors from './Constants.scss';
import { createMuiTheme } from '@material-ui/core';

export const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
        fontFamily: [
            'Roboto', "CircularStdmed", 'sans-serif'
        ].join(','),
    },
    palette: {
        primary: {
            main: colors.primarycolor,
        },
        secondary: {
            main: '#17252A',
        },
    },
});
