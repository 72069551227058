import React, { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withRouter } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import Hidden from '@material-ui/core/Hidden';
import HomeIcon from '@material-ui/icons/Home'
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import ForumIcon from '@material-ui/icons/Forum'

import { MuiThemeProvider } from '@material-ui/core/styles';

// Routing
import { HomeRoute, AboutRoute, ServicesRoute, ContactRoute } from '../Routing'

// Import react router
import { Link } from "react-router-dom";

// Need to log out in the drawer
import { Box } from '@material-ui/core';

import GeneralHelpers from '../../utils/Helpers/GeneralHelpers';
import './MainLayout.scss';

import { withTranslation } from 'react-i18next';
import ToogleLanguage from '../../components/ToogleLanguage/ToogleLanguage';

import Footer from '../../components/Footer/Footer';
import { theme } from '../../utils/Constants';

const ParticlesWrapper = lazy(() => import('../../components/ParticlesWrapper/ParticlesWrapper'));

const drawerWidth = 300;

export const mainStyles = theme => ({
    root: {
        display: 'flex',
        backgroundColor: "transparent",
    },
    appBar: {
        [theme.breakpoints.up('lg')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
        marginLeft: drawerWidth,
    },
    drawer: {
        [theme.breakpoints.up('lg')]: {
            width: drawerWidth,
            flexShrink: 0,
        },

    },
    menuButton: {
        marginLeft: 10,
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: "transparent",
        //backgroundColor: colors.primarycolor,
        color: "#fefefe",
        border: "none",
        [theme.breakpoints.up('lg')]: {
            //backgroundColor: colors.primarycolor,
        },
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        minHeight: "100vh",
    },
    icon: {
        color: "#fefefe",
    }
});

class MainLayout extends React.Component {
    state = {
        mobileOpen: false,
    };

    handleDrawerToggle = () => {
        this.setState(state => ({ mobileOpen: !state.mobileOpen }));
    };

    drawerCloseOnClick = () => {
        this.setState(state => ({ mobileOpen: false }));
    }

    render() {
        const { t, classes } = this.props;
        let AppTitle = "Welcome";
        const curentRoute = GeneralHelpers.checkNested(this.props, 'location', 'pathname');
        if (curentRoute === HomeRoute) AppTitle = t('home');
        if (curentRoute === AboutRoute) AppTitle = t('about');
        if (curentRoute === ServicesRoute) AppTitle = t('services');
        if (curentRoute === ContactRoute) AppTitle = t('contact');
        const drawer = (
            <div id="_drawer-container">
                <div className="logo-drawer">

                </div>
                <List>
                    <ListItem button component={Link} to={HomeRoute} onClick={() => this.drawerCloseOnClick()}>
                        <ListItemIcon><HomeIcon className={classes.icon} /></ListItemIcon>
                        <ListItemText primary={t('home')} />
                    </ListItem>
                    <Divider />
                    <ListItem button component={Link} to={ServicesRoute} onClick={() => this.drawerCloseOnClick()}>
                        <ListItemIcon><PlayCircleFilledIcon className={classes.icon} /></ListItemIcon>
                        <ListItemText primary={t('services')} />
                    </ListItem>
                    <Divider />
                    <ListItem button component={Link} to={AboutRoute} onClick={() => this.drawerCloseOnClick()}>
                        <ListItemIcon><EmojiPeopleIcon className={classes.icon} /></ListItemIcon>
                        <ListItemText primary={t('about')} />
                    </ListItem>
                    <Divider />
                    <ListItem button component={Link} to={ContactRoute} onClick={() => this.drawerCloseOnClick()}>
                        <ListItemIcon><ForumIcon className={classes.icon} /></ListItemIcon>
                        <ListItemText primary={t('contact')} />
                    </ListItem>
                    <Divider />
                </List>

                <ToogleLanguage />
            </div>
        );
        return (
            <div>
                <Suspense fallback={<div />}>
                    {
                        <Box id="particles-js"><ParticlesWrapper /></Box>
                    }
                </Suspense>

                <div id="main-layout">
                    <MuiThemeProvider theme={theme}>
                        <div className={classes.root}>
                            <CssBaseline />
                            <Hidden lgUp implementation="css">
                                <AppBar position="fixed" color="primary" className={classes.appBar}>
                                    <Toolbar>
                                        <Typography variant="h6" color="inherit" className="mobile-app-header">
                                            {AppTitle}
                                        </Typography>
                                        <Box flex={1} />
                                        <IconButton
                                            color="inherit"
                                            aria-label="Open drawer"
                                            onClick={this.handleDrawerToggle}
                                            className={classes.menuButton}
                                        >
                                            <MenuIcon />
                                        </IconButton>
                                    </Toolbar>
                                </AppBar>
                            </Hidden>
                            <nav className={classes.drawer}>
                                <Hidden lgUp implementation="css">
                                    <Drawer
                                        color="primary"
                                        className={classes.drawer}
                                        variant="temporary"
                                        open={this.state.mobileOpen}
                                        onClose={this.handleDrawerToggle}
                                        classes={{
                                            paper: classes.drawerPaper,
                                        }}
                                        anchor="right"
                                    >
                                        {drawer}
                                    </Drawer>
                                </Hidden>
                                <Hidden mdDown implementation="css">
                                    <Drawer
                                        color="primary"
                                        classes={{
                                            paper: classes.drawerPaper,
                                        }}
                                        variant="permanent"
                                        open
                                    >
                                        {drawer}
                                    </Drawer>
                                </Hidden>
                            </nav>
                            <main className={classes.content} >
                                <Hidden lgUp implementation="css"><div className={classes.toolbar} /></Hidden>
                                {
                                    /*
                                    <Hidden mdDown implementation="css">
                                    <Container maxWidth="md" className="page-title">
                                        <Typography variant="h1">{AppTitle}</Typography>
                                    </Container>
                                </Hidden>
                                    */
                                }

                                <Box width="100%" className="mainContent">{this.props.children}</Box>
                                <Footer />
                            </main>
                        </div>
                    </MuiThemeProvider>
                </div>
            </div >
        );
    }
}

MainLayout.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(mainStyles)(withRouter(withTranslation('pages')(MainLayout)));