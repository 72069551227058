import React, { PureComponent } from 'react';
import './ToogleLanguage.scss';
import { Box } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import fr_flag from './assets/iconfinder_France_flat_92086.png';
import en_flag from './assets/iconfinder_United-Kingdom_flat_92402.png';
import cn_flag from './assets/china.png';
import classNames from 'classnames';

class ToogleLanguage extends PureComponent {
    state = { selectedLanguage: "" }

    componentDidMount() {
        let currentLng = "fr";
        if (this.props.i18n.language) currentLng = this.props.i18n.language;
        const languageLocal = localStorage.getItem("language");
        if (languageLocal && (languageLocal === "fr" || languageLocal === 'en' || languageLocal === 'cn')) {
            currentLng = localStorage.getItem('language')
        }
        this.setLanguage(currentLng)
    }

    setLanguage(lng) {
        this.setState({
            selectedLanguage: lng,
        });
        localStorage.setItem('language', lng)
        this.props.i18n.changeLanguage(lng);
    }

    render() {
        let { selectedLanguage } = this.state;
        return (
            <Box className="ToogleLanguage" display="flex" flexWrap="wrap" alignItems="center">
                <Box flexGrow={1} />
                <Box className={classNames("flagcontainer", selectedLanguage === "fr" ? "selected" : null)}
                    onClick={() => this.setLanguage("fr")}>
                    <img src={fr_flag} alt="french flag" />
                </Box>
                <Box className={classNames("flagcontainer", selectedLanguage === "en" ? "selected" : null)}
                    onClick={() => this.setLanguage("en")}>
                    <img src={en_flag} alt="en flag" />
                </Box>
                <Box className={classNames("flagcontainer", selectedLanguage === "cn" ? "selected" : null)}
                    onClick={() => this.setLanguage("cn")}>
                    <img src={cn_flag} alt="cn flag" />
                </Box>
                <Box flexGrow={1} />
            </ Box>
        );
    }
}

export default withTranslation()(ToogleLanguage)