import React, { Component } from 'react';
import './NotFound.scss';
import { Box } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

class NotFound extends Component {
    render() {
        return (
            <Box className="NotFound" display="flex" flexDirection="column">
                <Box flexGrow={1} />
                <Box display="flex" flexDirection="row">
                    <Box flexGrow={1} />
                    <Box>
                        <h1>404</h1>
                        <h2>Page not found</h2>
                    </Box>
                    <Box flexGrow={1} />
                </Box>
                <Box flexGrow={1} />
            </Box>
        );
    }
}

export default withTranslation('notFound')(NotFound);