import React, { Component } from 'react';
import './Contact.scss';
import { Box, Container } from '@material-ui/core';
import { withTranslation, Trans } from 'react-i18next';
import ImgLogo from './../../assets/img/logo.svg'
import ImgLogoText from './../../assets/img/logoPlusText.svg'
import ImgQrcode from './../../assets/img/qr-code.png'
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import RoomIcon from '@material-ui/icons/Room';
import { Fade } from 'react-reveal';

class Contact extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { t } = this.props;
        return (
            <Box className="Contact">
                <Container maxWidth="lg" className="header">
                    <Box display="flex" flexDirection="column" style={{ minHeight: 'inherit' }}>
                        <Box className="upper-flex" flexGrow={1} />
                        <Box display="flex" alignItems='center' flexWrap='wrap'>
                            <Box>
                                <Fade left>
                                    <h1 className="main-title">{t('title')}</h1>
                                </Fade>
                            </Box>
                            <Box flexGrow={1} />
                            <Box className="sub-title" style={{ maxWidth: "800px" }}>
                                <Fade right>
                                    <Trans>
                                        {t('text')}
                                    </Trans>
                                </Fade>
                            </Box>
                        </Box>
                        <Box flexGrow={0.5} minHeight='25px' />
                        <Box display="flex" alignItems='center' flexWrap='no-wrap'>
                            <Box flexGrow={1} />
                            <Box>
                                <Fade bottom>
                                    <div className="flip-card">
                                        <div className="content-flip-card">
                                            <div className="front-flip-card">
                                                <Box display="flex" flexDirection="column" style={{ height: "100%", width: "100%" }}>
                                                    <Box className='header-card' display="flex">
                                                        <Box><img src={ImgLogo} alt="digital solutions logo" /></Box>
                                                        <Box flexGrow={1} />
                                                        <span>ycode.fr</span>
                                                    </Box>
                                                    <Box>
                                                        <h3>Yann Donazzolo</h3>
                                                    </Box>
                                                    <Box>
                                                        <h4>Digital Solution Engineer</h4>
                                                    </Box>
                                                    <Box flexGrow={1} display='flex' flexDirection='row' flexWrap="wrap" style={{ margin: "15px" }}>
                                                        <Box display='flex' flexDirection='column'>
                                                            <Box flexGrow={1} component="p" display="flex" alignContent="center">
                                                                <PhoneIcon style={{ marginRight: "15px" }} />
                                                                <span>+33 (0)6 87 48 27 80</span>
                                                            </Box>
                                                            <Box flexGrow={1} component="p" display="flex" alignContent="center">
                                                                <MailIcon style={{ marginRight: "15px" }} />
                                                                <span>yann@donazzolo.com</span>
                                                            </Box>
                                                            <Box flexGrow={1} component="p" display="flex" alignContent="center" style={{ marginBottom: "15px" }}>
                                                                <RoomIcon style={{ marginRight: "15px" }} />
                                                                <span>26 Bd Marechal Foch<br />38000 Grenoble</span>
                                                            </Box>
                                                        </Box>
                                                        <Box flexGrow={1} />
                                                        <Box flexGrow={1} className="qr-code-container">
                                                            <img src={ImgQrcode} alt="qrcode" />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </div>
                                            <div className="back-flip-card">
                                                <Box display="flex" flexDirection="column" style={{ height: "100%", width: "100%" }}>
                                                    <Box flexGrow={1} />
                                                    <Box display='flex'>
                                                        <Box flexGrow={1} />
                                                        <img src={ImgLogoText} alt="logo" />
                                                        <Box flexGrow={1} />
                                                    </Box>
                                                    <Box flexGrow={1} />
                                                    <Box>
                                                        <Box display='flex' margin="20px" flexWrap='wrap'>
                                                            <Box component="p" display="flex" alignContent="center">
                                                                <PhoneIcon style={{ marginRight: "15px" }} />
                                                                <span>+33 (0)6 87 48 27 80</span>
                                                            </Box>
                                                            <Box flexGrow={1} />
                                                            <Box component="p" display="flex" alignContent="center">
                                                                <MailIcon style={{ marginRight: "15px" }} />
                                                                <span>yann@donazzolo.com</span>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            </Box>
                            <Box flexGrow={1} />
                        </Box>
                        <Box flexGrow={1} />
                    </Box>
                </Container>
            </Box>
        );
    }
}

export default withTranslation('contact')(Contact);

/*
<Paragraph alignRight={true}
                    title={t('paragraph1.title')}
                    illustration={organize}
                    illustrationHeight={300}
                    illustrationWidth={600}>
                    <Trans>
                        <Typography variant="body1" component='span'>
                            {t('paragraph1.content')}
                        </Typography>
                    </Trans>
                </Paragraph>
*/