import React, { PureComponent } from 'react';
import './Footer.scss';
import { Box, Container, Divider } from '@material-ui/core';
import imgFooterBg from './../../assets/img/footerBg.webp';
import Pulse from 'react-reveal/Pulse';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import RoomIcon from '@material-ui/icons/Room';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { HomeRoute, ContactRoute, ServicesRoute, AboutRoute } from '../../pages/Routing';

class Footer extends PureComponent {
    render() {
        const { t } = this.props;
        return (
            <Box className="Footer">
                <Box className="footer-top">
                    <Box className="bg-container">
                        <Pulse forever duration={2000}>
                            <img className="img-bg" alt="" src={imgFooterBg} />
                        </Pulse>
                    </Box>

                    <Container className="container" maxWidth="lg">
                        <div className="row">
                            <div className="col-sm">
                                <h3>{t('about.title')}</h3>
                                <p className="about">{t('about.text')}</p>
                            </div>
                            <div className="col-sm">
                                <h3>{t('map.title')}</h3>
                                <p><Link to={HomeRoute}>{t('map.home')}</Link></p>
                                <p><Link to={ServicesRoute}>{t('map.services')}</Link></p>
                                <p><Link to={AboutRoute}>{t('map.about')}</Link></p>
                                <p><Link to={ContactRoute}>{t('map.contact')}</Link></p>
                            </div>
                            <div className="col-sm">
                                <h3>Contact</h3>
                                <Box component="p" display="flex" alignContent="center">
                                    <PhoneIcon style={{ marginRight: "15px" }} />
                                    <span>+33 (0)6 87 48 27 80</span>
                                </Box>
                                <Box component="p" display="flex" alignContent="center">
                                    <MailIcon style={{ marginRight: "15px" }} />
                                    <span>yann@donazzolo.com</span>
                                </Box>
                                <Box component="p" display="flex" alignContent="center">
                                    <RoomIcon style={{ marginRight: "15px" }} />
                                    <span>26 Bd Marechal Foch, 38000 Grenoble</span>
                                </Box>
                            </div>
                        </div>
                    </Container>
                    <Divider style={{ backgroundColor: "rgba(0, 103, 160, 0.5)" }} />
                </Box>
                <Box className="copyright" display="flex">
                    <Box flexGrow={1} />
                    <Box>
                        <p>© 2020 ycode.fr. All Rights Reserved</p>
                    </Box>
                    <Box flexGrow={1} />
                    {/*
                    <Box><p>Terms & Conditions</p></Box>
                    */}

                </Box>
            </ Box>
        );
    }
}

export default withTranslation('footer')(Footer);