import React, { Component } from 'react';
import './Services.scss';
import { Box, Container, Button } from '@material-ui/core';
import { withTranslation, Trans } from 'react-i18next';
import { Fade, Zoom } from 'react-reveal';
import ImgProto from './../../assets/img/proto.svg'
import { Link } from 'react-router-dom';
import { AboutRoute } from '../Routing';
import ImgShape1 from './../../assets/img/shape/shape-64.svg'
import ImgShape2 from './../../assets/img/shape/shape-65.svg'
import CheckIcon from '@material-ui/icons/Check';

class Services extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        const { t } = this.props;
        return (
            <Box className="Services">
                <Container maxWidth="lg" className="header">
                    <Box display="flex" flexDirection="column" style={{ minHeight: 'inherit' }}>
                        <Box className="upper-flex" flexGrow={1} />
                        <Box display="flex" alignItems='center' flexWrap='no-wrap'>
                            <Box flexGrow={1}>
                                <Fade bottom>
                                    <h1 className="main-title">{t('header.title')}</h1>
                                </Fade>
                                <Fade bottom delay={400}>
                                    <h2 className="sub-title">
                                        {t('header.text')}
                                    </h2>
                                </Fade>
                                <Box display="flex" flexWrap="wrap">
                                    <Box display="flex" flexWrap="wrap" flexGrow={1}>
                                        <Box flexGrow={1} />
                                        <Fade left delay={500}>
                                            <Button component="a" href="#web" variant="contained" className="empty-button">
                                                {t('header.web')}
                                            </Button>
                                        </Fade>
                                        <Box flexGrow={1} />
                                    </Box>
                                    <Box display="flex" flexWrap="wrap" flexGrow={1}>
                                        <Box flexGrow={1} />
                                        <Box className='button'>
                                            <Fade left delay={500}>
                                                <Button component="a" href="#app" variant="contained" className="empty-button">
                                                    {t('header.mobile')}
                                                </Button>
                                            </Fade>
                                        </Box>
                                        <Box flexGrow={1} />
                                    </Box>
                                </Box>
                                <Box display="flex" flexWrap="wrap" flexGrow={1}>
                                    <Box flexGrow={1} />
                                    <Box className='button'>
                                        <Fade left delay={500}>
                                            <Button component="a" href="#custom" variant="contained" className="empty-button">
                                                {t('header.custom')}
                                            </Button>
                                        </Fade>
                                    </Box>
                                    <Box flexGrow={1} />
                                </Box>
                            </Box>

                            <Box flexGrow={1} className="illustration">
                                <Zoom delay={200}>
                                    <img src={ImgProto} alt="" />
                                </Zoom>
                            </Box>

                        </Box>
                        <Box flexGrow={1} />
                    </Box>
                </Container>

                <a name="web" />
                <section className="price-web">
                    <div className={"section-head full-width"}>
                        <h2 className="title">{t('web.title')}</h2>
                    </div>
                    <Fade right>
                        <img src={ImgShape1} alt="" className="shape-two" />
                    </Fade>
                    <Fade left>
                        <img src={ImgShape2} alt="" className="shape-three" />
                    </Fade>
                    <Container maxWidth="lg">
                        <div className="text">
                            <Trans>{t('web.text')}</Trans>
                        </div>
                        <div className="row text-center justify-content-md-center prices-table">
                            <div className="col-lg-4">
                                <div className="price-container">
                                    <div className="price-header">
                                        <div className="price-title">
                                            <h2>{t('web.web.title')}</h2>
                                        </div>

                                        <div className="price-price">
                                            <h4>{t('web.web.from')}</h4>
                                            <h3>{t('web.web.price')}</h3>
                                        </div>
                                    </div>
                                    <div className="price-body">
                                        <p>{t('web.web.text')}</p>
                                        <ul className="list-unstyled">
                                            <li className="mb-3">
                                                <Box display='flex' alignItems='center'>
                                                    <CheckIcon style={{ color: "green", marginRight: "0.5em" }} />
                                                    {t('web.web.dev')}
                                                </Box>
                                            </li>
                                            <li className="mb-3">
                                                <Box display='flex' alignItems='center'>
                                                    <CheckIcon style={{ color: "green", marginRight: "0.5em" }} />
                                                    {t('web.web.upload')}
                                                </Box>
                                            </li>
                                            <li className="mb-3">
                                                <Box display='flex' alignItems='center'>
                                                    <CheckIcon style={{ color: "green", marginRight: "0.5em" }} />
                                                    {t('web.web.domain')}
                                                </Box>
                                            </li>
                                            <li className="mb-3">
                                                <Box display='flex' alignItems='center'>
                                                    <CheckIcon style={{ color: "green", marginRight: "0.5em" }} />
                                                    {t('web.web.host')}
                                                </Box>
                                            </li>
                                            <li className="mb-3">
                                                <Box display='flex' alignItems='center'>
                                                    <CheckIcon style={{ color: "green", marginRight: "0.5em" }} />
                                                    {t('web.web.formation')}
                                                </Box>
                                            </li>
                                            <li className="mb-3">
                                                <Box display='flex' alignItems='center'>
                                                    <CheckIcon style={{ color: "green", marginRight: "0.5em" }} />
                                                    {t('web.web.delay')}
                                                </Box>
                                            </li>
                                        </ul>
                                    </div>
                                    <div style={{ flexGrow: 1 }} />
                                    <div className="price-button">
                                        <Button component={Link} to={AboutRoute} variant="contained" className="solid-button">
                                            Contact
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="price-container">
                                    <div className="price-header">
                                        <div className="price-title">
                                            <h2>{t('web.ecommerce.title')}</h2>
                                        </div>

                                        <div className="price-price">
                                            <h4>{t('web.ecommerce.from')}</h4>
                                            <h3>{t('web.ecommerce.price')}</h3>
                                        </div>
                                    </div>
                                    <div className="price-body">
                                        <p>{t('web.ecommerce.text')}</p>
                                        <ul className="list-unstyled">
                                            <li className="mb-3">
                                                <Box display='flex' alignItems='center'>
                                                    <CheckIcon style={{ color: "green", marginRight: "0.5em" }} />
                                                    {t('web.ecommerce.dev')}
                                                </Box>
                                            </li>
                                            <li className="mb-3">
                                                <Box display='flex' alignItems='center'>
                                                    <CheckIcon style={{ color: "green", marginRight: "0.5em" }} />
                                                    {t('web.ecommerce.upload')}
                                                </Box>
                                            </li>
                                            <li className="mb-3">
                                                <Box display='flex' alignItems='center'>
                                                    <CheckIcon style={{ color: "green", marginRight: "0.5em" }} />
                                                    {t('web.ecommerce.domain')}
                                                </Box>
                                            </li>
                                            <li className="mb-3">
                                                <Box display='flex' alignItems='center'>
                                                    <CheckIcon style={{ color: "green", marginRight: "0.5em" }} />
                                                    {t('web.ecommerce.host')}
                                                </Box>
                                            </li>
                                            <li className="mb-3">
                                                <Box display='flex' alignItems='center'>
                                                    <CheckIcon style={{ color: "green", marginRight: "0.5em" }} />
                                                    {t('web.ecommerce.formation')}
                                                </Box>
                                            </li>
                                            <li className="mb-3">
                                                <Box display='flex' alignItems='center'>
                                                    <CheckIcon style={{ color: "green", marginRight: "0.5em" }} />
                                                    {t('web.ecommerce.interface')}
                                                </Box>
                                            </li>
                                        </ul>
                                    </div>
                                    <div style={{ flexGrow: 1 }} />
                                    <div className="price-button">
                                        <Button component={Link} to={AboutRoute} variant="contained" className="solid-button">
                                            Contact
                                    </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="price-container">
                                    <div className="price-header">
                                        <div className="price-title">
                                            <h2>{t('web.webmaster.title')}</h2>
                                        </div>

                                        <div className="price-price">
                                            <h4>{t('web.webmaster.from')}</h4>
                                            <h3>{t('web.webmaster.price')}</h3>
                                        </div>
                                    </div>
                                    <div className="price-body">
                                        <p>{t('web.webmaster.text')}</p>
                                        <ul className="list-unstyled">
                                            <li className="mb-3">
                                                <Box display='flex' alignItems='center'>
                                                    <CheckIcon style={{ color: "green", marginRight: "0.5em" }} />
                                                    {t('web.webmaster.host')}
                                                </Box>
                                            </li>
                                            <li className="mb-3">
                                                <Box display='flex' alignItems='center'>
                                                    <CheckIcon style={{ color: "green", marginRight: "0.5em" }} />
                                                    {t('web.webmaster.domain')}
                                                </Box>
                                            </li>
                                            <li className="mb-3">
                                                <Box display='flex' alignItems='center'>
                                                    <CheckIcon style={{ color: "green", marginRight: "0.5em" }} />
                                                    {t('web.webmaster.update')}
                                                </Box>
                                            </li>
                                            <li className="mb-3">
                                                <Box display='flex' alignItems='center'>
                                                    <CheckIcon style={{ color: "green", marginRight: "0.5em" }} />
                                                    {t('web.webmaster.advise')}
                                                </Box>
                                            </li>
                                        </ul>
                                    </div>
                                    <div style={{ flexGrow: 1 }} />
                                    <div className="price-button">
                                        <Button component={Link} to={AboutRoute} variant="contained" className="solid-button">
                                            Contact
                                    </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>

                <a name="app" />
                <section className="price-app">
                    <div className={"section-head full-width"}>
                        <h2 className="title">{t('mobile.title')}</h2>
                    </div>
                    <Container maxWidth="lg">
                        <div className="text">
                            <Trans>{t('mobile.text')}</Trans>
                        </div>
                        <div className="row text-center justify-content-md-center prices-table">
                            <div className="col-lg-4">
                                <div className="price-container">
                                    <div className="price-header">
                                        <div className="price-title">
                                            <h2>{t('mobile.pwa.title')}</h2>
                                        </div>

                                        <div className="price-price">
                                            <h4>{t('mobile.pwa.from')}</h4>
                                            <h3>{t('mobile.pwa.price')}</h3>
                                        </div>
                                    </div>
                                    <div className="price-body">
                                        <p>{t('mobile.pwa.text')}</p>
                                        <ul className="list-unstyled">
                                            <li className="mb-3">
                                                <Box display='flex' alignItems='center'>
                                                    <CheckIcon style={{ color: "green", marginRight: "0.5em" }} />
                                                    {t('mobile.pwa.multi')}
                                                </Box>
                                            </li>
                                            <li className="mb-3">
                                                <Box display='flex' alignItems='center'>
                                                    <CheckIcon style={{ color: "green", marginRight: "0.5em" }} />
                                                    {t('mobile.pwa.dev')}
                                                </Box>
                                            </li>
                                            <li className="mb-3">
                                                <Box display='flex' alignItems='center'>
                                                    <CheckIcon style={{ color: "green", marginRight: "0.5em" }} />
                                                    {t('mobile.pwa.upload')}
                                                </Box>
                                            </li>
                                            <li className="mb-3">
                                                <Box display='flex' alignItems='center'>
                                                    <CheckIcon style={{ color: "green", marginRight: "0.5em" }} />
                                                    {t('mobile.pwa.host')}
                                                </Box>
                                            </li>
                                        </ul>
                                    </div>
                                    <div style={{ flexGrow: 1 }} />
                                    <div className="price-button">
                                        <Button component={Link} to={AboutRoute} variant="contained" className="solid-button">
                                            Contact
                                    </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="price-container">
                                    <div className="price-header">
                                        <div className="price-title">
                                            <h2>{t('mobile.android.title')}</h2>
                                        </div>

                                        <div className="price-price">
                                            <h4>{t('mobile.android.from')}</h4>
                                            <h3>{t('mobile.android.price')}</h3>
                                        </div>
                                    </div>
                                    <div className="price-body">
                                        <p>{t('mobile.android.text')}</p>
                                        <ul className="list-unstyled">
                                            <li className="mb-3">
                                                <Box display='flex' alignItems='center'>
                                                    <CheckIcon style={{ color: "green", marginRight: "0.5em" }} />
                                                    {t('mobile.android.dev')}
                                                </Box>
                                            </li>
                                            <li className="mb-3">
                                                <Box display='flex' alignItems='center'>
                                                    <CheckIcon style={{ color: "green", marginRight: "0.5em" }} />
                                                    {t('mobile.android.apk')}
                                                </Box>
                                            </li>
                                            <li className="mb-3">
                                                <Box display='flex' alignItems='center'>
                                                    <CheckIcon style={{ color: "green", marginRight: "0.5em" }} />
                                                    {t('mobile.android.google')}
                                                </Box>
                                            </li>
                                        </ul>
                                    </div>
                                    <div style={{ flexGrow: 1 }} />
                                    <div className="price-button">
                                        <Button component={Link} to={AboutRoute} variant="contained" className="solid-button">
                                            Contact
                                    </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>

                <a name="custom" />
                <section className="price-custom">
                    <div className={"section-head full-width"}>
                        <h2 className="title">{t('custom.title')}</h2>
                    </div>
                    <Fade right>
                        <img src={ImgShape1} alt="" className="shape-two" />
                    </Fade>
                    <Fade left>
                        <img src={ImgShape2} alt="" className="shape-three" />
                    </Fade>
                    <Container maxWidth="lg">
                        <div className="text">
                            <Trans>{t('custom.text')}</Trans>
                        </div>
                        <div className="row text-center justify-content-md-center prices-table">
                            <div className="col-lg-4">
                                <div className="price-container">
                                    <div className="price-header">
                                        <div className="price-title">
                                            <h2>{t('custom.project.title')}</h2>
                                        </div>

                                        <div className="price-price">
                                            <h4>{t('custom.project.from')}</h4>
                                            <h3>{t('custom.project.price')}</h3>
                                        </div>
                                    </div>
                                    <div className="price-body">
                                        <p>{t('custom.project.text')}</p>
                                        <ul className="list-unstyled">
                                            <li className="mb-3">
                                                <Box display='flex' alignItems='center'>
                                                    <CheckIcon style={{ color: "green", marginRight: "0.5em" }} />
                                                    {t('custom.project.dev')}
                                                </Box>
                                            </li>
                                            <li className="mb-3">
                                                <Box display='flex' alignItems='center'>
                                                    <CheckIcon style={{ color: "green", marginRight: "0.5em" }} />
                                                    {t('custom.project.spec')}
                                                </Box>
                                            </li>
                                            <li className="mb-3">
                                                <Box display='flex' alignItems='center'>
                                                    <CheckIcon style={{ color: "green", marginRight: "0.5em" }} />
                                                    {t('custom.project.managment')}
                                                </Box>
                                            </li>
                                            <li className="mb-3">
                                                <Box display='flex' alignItems='center'>
                                                    <CheckIcon style={{ color: "green", marginRight: "0.5em" }} />
                                                    {t('custom.project.formation')}
                                                </Box>
                                            </li>
                                        </ul>
                                    </div>
                                    <div style={{ flexGrow: 1 }} />
                                    <div className="price-button">
                                        <Button component={Link} to={AboutRoute} variant="contained" className="solid-button">
                                            Contact
                                    </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="price-container">
                                    <div className="price-header">
                                        <div className="price-title">
                                            <h2>{t('custom.time.title')}</h2>
                                        </div>

                                        <div className="price-price">
                                            <h4>{t('custom.time.from')}</h4>
                                            <h3>{t('custom.time.price')}</h3>
                                        </div>
                                    </div>
                                    <div className="price-body">
                                        <p>{t('custom.time.text')}</p>
                                        <ul className="list-unstyled">
                                            <li className="mb-3">
                                                <Box display='flex' alignItems='center'>
                                                    <CheckIcon style={{ color: "green", marginRight: "0.5em" }} />
                                                    {t('custom.time.language')}
                                                </Box>
                                            </li>
                                            <li className="mb-3">
                                                <Box display='flex' alignItems='center'>
                                                    <CheckIcon style={{ color: "green", marginRight: "0.5em" }} />
                                                    {t('custom.time.framwork')}
                                                </Box>
                                            </li>
                                            <li className="mb-3">
                                                <Box display='flex' alignItems='center'>
                                                    <CheckIcon style={{ color: "green", marginRight: "0.5em" }} />
                                                    {t('custom.time.aws')}
                                                </Box>
                                            </li>
                                            <li className="mb-3">
                                                <Box display='flex' alignItems='center'>
                                                    <CheckIcon style={{ color: "green", marginRight: "0.5em" }} />
                                                    {t('custom.time.agile')}
                                                </Box>
                                            </li>
                                        </ul>
                                    </div>
                                    <div style={{ flexGrow: 1 }} />
                                    <div className="price-button">
                                        <Button component={Link} to={AboutRoute} variant="contained" className="solid-button">
                                            Contact
                                    </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
            </Box >
        );
    }
}

export default withTranslation('services')(Services);