import React, { Component } from 'react';
import './About.scss';
import { Box, Container } from '@material-ui/core';
import { withTranslation, Trans } from 'react-i18next';
import ImgProto from './../../assets/img/profile.webp'
import { Fade, Zoom } from 'react-reveal';
import PortfolioComp from '../../components/PortfolioComp/PortfolioComp';

class About extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { t } = this.props;
        return (
            <Box className="About">
                <Container maxWidth="lg" className="header">
                    <Box display="flex" flexWrap="wrap" style={{ minHeight: 'inherit' }}>
                        <Box flexGrow={1}
                            display="flex"
                            flexDirection="column"
                            className="about-text">

                            <Box flexGrow={1} />
                            <Box>
                                <Fade bottom>
                                    <h1 className="main-title">{t('about')}</h1>
                                </Fade>
                                <Fade bottom delay={400}>
                                    <Box className="sub-title" style={{ textAlign: "justify" }}>
                                        <Trans>
                                            {t('text')}
                                        </Trans>
                                    </Box>

                                </Fade>
                            </Box>
                            <Box flexGrow={1} />
                        </Box>
                        <Box flexGrow={1}
                            display="flex"
                            className="illustration-about">
                            <Box flexGrow={1} />

                            <Box display="flex"
                                flexDirection="column">
                                <Box flexGrow={1} />
                                <Zoom delay={200}>
                                    <img src={ImgProto} alt="" />
                                </Zoom>
                                <Box flexGrow={1} />
                            </Box>

                            <Box flexGrow={1} />
                        </Box>
                    </Box>
                </Container>

                <section className="portfolio">
                    <div className="section-head full-width">
                        <h2 className="title">Portfolio</h2>
                    </div>
                    <Container maxWidth="lg" data-scroll-index="3">
                        <PortfolioComp />
                    </Container>
                </section>

            </Box>
        );
    }
}

export default withTranslation('about')(About);