import React, { PureComponent } from 'react';
import './ValidCookies.scss';
import { Button } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import CookieConsent from "react-cookie-consent";

class ValidCookies extends PureComponent {
    render() {
        const { t } = this.props;
        return (
            <CookieConsent
                containerClasses="ValidCookies"
                location="bottom"
                ButtonComponent={Button}
                buttonClasses="solid-button"
                buttonWrapperClasses='button-container'
                buttonText={t('button')}
                style={{ textAlign: "center" }}>
                {t('text')}
            </CookieConsent>
        );
    }
}

export default withTranslation('cookies')(ValidCookies);