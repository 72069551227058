import React, { Component } from 'react';
import './Home.scss';
import { Box, Container, Button } from '@material-ui/core';
import ImgBgHome from './../../assets/img/bg_home.webp'
import ImgShape1 from './../../assets/img/shape/shape-64.svg'
import ImgShape2 from './../../assets/img/shape/shape-65.svg'
import { withTranslation, Trans } from 'react-i18next';
import Typist from 'react-typist';
import TypistLoop from 'react-typist-loop';
import 'babel-polyfill';
import { Fade, Zoom } from 'react-reveal';
import PortfolioComp from '../../components/PortfolioComp/PortfolioComp';
import { Link } from 'react-router-dom';
import { AboutRoute, ServicesRoute } from '../Routing';

class Home extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { t } = this.props;
        const headWordsList = [t('type-word.cloud'),
        t('type-word.custom'),
        t('type-word.ai'),
        t('type-word.web'),
        t('type-word.ecommerce'),
        t('type-word.mobile')]
        return (
            <Box className="Home">
                <Container maxWidth="lg" className="header">
                    <Box display="flex" flexDirection="column" style={{ minHeight: 'inherit' }}>
                        <Box className="upper-flex" flexGrow={1} />
                        <Box display="flex" alignItems='center' flexWrap='no-wrap'>
                            <Box flexGrow={1}>
                                <Fade bottom>
                                    <h1 className="main-title"><Trans>{t('title')}</Trans></h1>
                                </Fade>
                                <Fade bottom delay={200}>
                                    <h2 className="sub-title">
                                        <Trans>{t('subtitle')}</Trans>
                                        <br />
                                        <TypistLoop>
                                            {headWordsList.map((text, index) => <Typist key={index} avgTypingDelay={50} stdTypingDelay={0}>
                                                <span>{text}</span>
                                                <Typist.Backspace count={text.length} delay={200} />
                                            </Typist>)}
                                        </TypistLoop>
                                    </h2>
                                </Fade>
                                <Box display="flex" flexWrap="wrap">
                                    <Box display="flex" flexWrap="wrap" flexGrow={1}>
                                        <Box flexGrow={1} />
                                        <Fade left delay={500}>
                                            <Button component={Link} to={ServicesRoute} variant="contained" className="empty-button">
                                                {t('button.services')}
                                            </Button>
                                        </Fade>
                                        <Box flexGrow={1} />
                                    </Box>
                                    <Box display="flex" flexWrap="wrap" flexGrow={1}>
                                        <Box flexGrow={1} />
                                        <Box className='button'>
                                            <Fade left delay={500}>
                                                <Button component={Link} to={AboutRoute} variant="contained" className="empty-button">
                                                    {t('button.about')}
                                                </Button>
                                            </Fade>
                                        </Box>
                                        <Box flexGrow={1} />
                                    </Box>
                                </Box>
                            </Box>

                            <Box flexGrow={1} className="illustration">
                                <Zoom delay={200}>
                                    <img src={ImgBgHome} alt="" />
                                </Zoom>
                            </Box>

                        </Box>
                        <Box flexGrow={1} />
                    </Box>
                </Container>

                <section className="services">
                    <div className={"section-head full-width"}>
                        <h2 className="title">{t('services.title')}</h2>
                    </div>
                    <Container maxWidth="lg" data-scroll-index="2">
                        <Fade right>
                            <img src={ImgShape1} alt="" className="shape-two" />
                        </Fade>
                        <Fade left delay={500}>
                            <img src={ImgShape2} alt="" className="shape-three" />
                        </Fade>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="item">
                                        <h3>{t('services.custom.title')}</h3>
                                        <p>{t('services.custom.paragraph')}</p>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="item">
                                        <h3>{t('services.cloud.title')}</h3>
                                        <p>{t('services.cloud.paragraph')}</p>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="item">
                                        <h3>{t('services.support.title')}</h3>
                                        <p>{t('services.support.paragraph')}</p>
                                    </div>
                                </div>
                                <div className="bord full-width"></div>
                                <div className="col-lg-4">
                                    <div className="item">
                                        <h3>{t('services.tests.title')}</h3>
                                        <p>{t('services.tests.paragraph')}</p>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="item mb-sm50">
                                        <h3>{t('services.web.title')}</h3>
                                        <p>{t('services.web.paragraph')}</p>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="item">
                                        <h3>{t('services.mobile.title')}</h3>
                                        <p>{t('services.mobile.paragraph')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </Container>
                    <Box display='flex' style={{ marginTop: 30 }}>
                        <Box flexGrow={1} />
                        <Fade right >
                            <Button component={Link} to={ServicesRoute} className="solid-button">
                                {t('button.information-price')}
                            </Button>
                        </Fade>
                    </Box>
                </section>

                <section className="portfolio">
                    <div className="section-head full-width">
                        <h2 className="title">Portfolio</h2>
                    </div>
                    <Container maxWidth="lg" data-scroll-index="3">
                        <PortfolioComp />
                    </Container>
                    <Box display='flex' style={{ marginTop: 30 }}>
                        <Box flexGrow={1} />
                        <Fade right >
                            <Button component={Link} to={AboutRoute} className="solid-button">
                                {t('button.more')}
                            </Button>
                        </Fade>
                    </Box>
                </section>
            </Box >
        );
    }
}

export default withTranslation('home')(Home);

/*

                <Box display='flex'>
                        <Box flexGrow={1} />
                        <Box>
                            <h2>Solutions Logiciels</h2>
                            <h2>Sur Mesures</h2>
                            <h1>Cloud</h1>
                        </Box>
                        <Box flexGrow={1} />
                    </Box>
                    <Paragraph alignRight={true}
                        title={t('paragraph1.title')}
                        illustration={organize}
                        illustrationHeight={400}
                        illustrationWidth={600}>
                        <Trans>
                            <Typography variant="body1" component='span'>
                                {t('paragraph1.content')}
                            </Typography>
                        </Trans>
                    </Paragraph>

                    <Paragraph alignRight={false}
                        title={t('paragraph2.title')}
                        illustration={imgExpert}
                        illustrationHeight={300}
                        illustrationWidth={400}>
                        <Trans>
                            <Typography variant="body1" component='span'>
                                {t('paragraph2.content')}
                            </Typography>
                        </Trans>
                    </Paragraph>

                    <Paragraph alignRight={true}
                        title={t('paragraph3.title')}
                        illustration={imgHandshake}
                        illustrationHeight={400}
                        illustrationWidth={400}>
                        <Trans>
                            <Typography variant="body1" component='span'>
                                {t('paragraph3.content')}
                            </Typography>
                        </Trans>
                    </Paragraph>

                    <Paragraph alignRight={false}
                        title={t('paragraph4.title')}
                        illustration={imgScrum}
                        illustrationHeight={300}
                        illustrationWidth={400}>
                        <Trans>
                            <Typography variant="body1" component='span'>
                                {t('paragraph4.content')}
                            </Typography>
                        </Trans>
                    </Paragraph>

                    <Paragraph alignRight={true}
                        title={t('paragraph5.title')}
                        illustration={imgPriceTag}
                        illustrationHeight={300}
                        illustrationWidth={300}>
                        <Trans>
                            <Typography variant="body1" component='span'>
                                {t('paragraph5.content')}
                            </Typography>
                        </Trans>
                    </Paragraph>
*/