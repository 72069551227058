import React, { PureComponent } from 'react';
import './PortfolioComp.scss';
import { Box, Button, ThemeProvider } from '@material-ui/core';
import Shuffle from 'shufflejs';
import { withTranslation } from 'react-i18next';

import ImgTimes from './../../assets/img/portfolio/times.webp'
import ImgLabScan from './../../assets/img/portfolio/lab-scan.webp'
import ImgAperoom from './../../assets/img/portfolio/aperoom.webp'
import ImgRecognimage from './../../assets/img/portfolio/recognimage.webp'
import ImgPicky from './../../assets/img/portfolio/picky.webp'
import ImgCavavin from './../../assets/img/portfolio/cavavin.webp'
import ImgEmotion from './../../assets/img/portfolio/emotion.webp'
import ImgClickndrone from './../../assets/img/portfolio/clickndrone.webp'
import { theme } from '../../utils/Constants';

class PortfolioComp extends PureComponent {
    state = {
        filter: '',
    }
    componentDidMount() {
        var element = document.querySelector('.my-shuffle-container');

        this.shuffleInstance = new Shuffle(element, {
            itemSelector: '.picture-item'
        });
        setTimeout(this.filter(''), 1000)
    }

    filter(filter) {
        if (this.shuffleInstance) {
            this.shuffleInstance.filter(filter);
            this.setState({ filter: filter });
        }
    }
    render() {
        const { t } = this.props;
        return (
            <ThemeProvider theme={theme}>
            <Box className="PortfolioComp">
                <p style={{ marginBottom: '2em', fontSize: '1.5em' }}>{t('disclamer')}</p>
                <Box className="filters-group">
                    <Button variant={this.state.filter === '' ? 'contained' : null} color="primary" onClick={() => this.filter('')}>{t('categories.all')}</Button>
                    <Button variant={this.state.filter === 'web' ? 'contained' : null} color="primary" onClick={() => this.filter('web')}>{t('categories.web')}</Button>
                    {/* 
                    <Button variant={this.state.filter === 'eCommerce' ? 'contained' : null} color="primary" onClick={() => this.filter('eCommerce')}>{t('categories.ecommerce')}</Button>
                    */}
                    <Button variant={this.state.filter === 'custom' ? 'contained' : null} color="primary" onClick={() => this.filter('custom')}>{t('categories.custom')}</Button>
                    <Button variant={this.state.filter === 'cloud' ? 'contained' : null} color="primary" onClick={() => this.filter('cloud')}>{t('categories.cloud')}</Button>
                    <Button variant={this.state.filter === 'AI' ? 'contained' : null} color="primary" onClick={() => this.filter('AI')}>{t('categories.ai')}</Button>
                    <Button variant={this.state.filter === 'mobile' ? 'contained' : null} color="primary" onClick={() => this.filter('mobile')}>{t('categories.mobile')}</Button>                </Box>
                <div className="container">
                    <div className="row my-shuffle-container">
                        <div className="col-lg-6 col-xl-4 items picture-item" data-groups='["custom", "cloud"]' data-title="times">
                            <div className="item-img">
                                <img src={ImgTimes} alt={t('times.alt')} />
                                <Box display='flex' heigh='100%' flexDirection='column' className="item-img-overlay">
                                    <Box flexGrow={1} />
                                    <div>
                                        <h6>{t('categories.custom')} . {t('categories.cloud')}</h6>
                                        <h5>{t('times.title')}</h5>
                                        <p>{t('times.content')}</p>
                                    </div>
                                    <Box flexGrow={1} />
                                </Box>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-4 items picture-item" data-groups='["custom", "cloud"]' data-title="scan-lab">
                            <div className="item-img">
                                <img src={ImgLabScan} alt={t('lab-scan.alt')} />
                                <Box display='flex' heigh='100%' flexDirection='column' className="item-img-overlay">
                                    <Box flexGrow={1} />
                                    <div>
                                        <h6>{t('categories.custom')} . {t('categories.cloud')}</h6>
                                        <h5>{t('lab-scan.title')}</h5>
                                        <p>{t('lab-scan.content')}</p>
                                    </div>
                                    <Box flexGrow={1} />
                                </Box>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-4 items picture-item" data-groups='["web"]' data-title="Aperoom">
                            <div className="item-img">
                                <img src={ImgAperoom} alt={t('aperoom.alt')} />
                                <Box display='flex' heigh='100%' flexDirection='column' className="item-img-overlay">
                                    <Box flexGrow={1} />
                                    <div>
                                        <h6>{t('categories.web')}</h6>
                                        <h5>{t('aperoom.title')}</h5>
                                        <p>{t('aperoom.content')}</p>
                                        <a href='https://www.aperoom.io' target="_blank" rel="noopener noreferrer" className='link'>Visit</a>
                                    </div>
                                    <Box flexGrow={1} />
                                </Box>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-4 items picture-item" data-groups='["web","AI"]' data-title="Recognimage">
                            <div className="item-img">
                                <img src={ImgRecognimage} alt={t('recognimage.alt')} />
                                <Box display='flex' heigh='100%' flexDirection='column' className="item-img-overlay">
                                    <Box flexGrow={1} />
                                    <div>
                                        <h6>{t('categories.web')} . {t('categories.ai')}</h6>
                                        <h5>{t('recognimage.title')}</h5>
                                        <p>{t('recognimage.content')}</p>
                                        <a href='https://do93zxpqjd34l.cloudfront.net/' target="_blank" rel="noopener noreferrer" className='link'>Visit (In progress)</a>
                                    </div>
                                    <Box flexGrow={1} />
                                </Box>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-4 items picture-item" data-groups='["mobile"]' data-title="Picky eaters">
                            <div className="item-img">
                                <img src={ImgPicky} alt={t('picky.alt')} />
                                <Box display='flex' heigh='100%' flexDirection='column' className="item-img-overlay">
                                    <Box flexGrow={1} />
                                    <div>
                                        <h6>{t('categories.mobile')}</h6>
                                        <h5>{t('picky.title')}</h5>
                                        <p>{t('picky.content')}</p>
                                        <a href='https://play.google.com/store/apps/details?id=com.pickyeaters&hl=en' target="_blank" rel="noopener noreferrer" className='link'>Visit</a>
                                    </div>
                                    <Box flexGrow={1} />
                                </Box>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-4 items picture-item" data-groups='["web"]' data-title="clickndrone">
                            <div className="item-img">
                                <img src={ImgClickndrone} alt={t('clickndrone.alt')} />
                                <Box display='flex' heigh='100%' flexDirection='column' className="item-img-overlay">
                                    <Box flexGrow={1} />
                                    <div>
                                        <h6>{t('categories.web')}</h6>
                                        <h5>{t('clickndrone.title')}</h5>
                                        <p>{t('clickndrone.content')}</p>
                                        <a href='https://www.clickndrone.com/' target="_blank" rel="noopener noreferrer" className='link'>Visit</a>
                                    </div>
                                    <Box flexGrow={1} />
                                </Box>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-4 items picture-item" data-groups='["custom"]' data-title="emotion">
                            <div className="item-img">
                                <img src={ImgEmotion} alt={t('emotion.alt')} />
                                <Box display='flex' heigh='100%' flexDirection='column' className="item-img-overlay">
                                    <Box flexGrow={1} />
                                    <div>
                                        <h6>{t('categories.custom')}</h6>
                                        <h5>{t('emotion.title')}</h5>
                                        <p>{t('emotion.content')}</p>
                                        <a href='https://www.sensefly.com/' target="_blank" rel="noopener noreferrer" className='link'>Visit</a>
                                    </div>
                                    <Box flexGrow={1} />
                                </Box>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-4 items picture-item" data-groups='["web", "mobile"]' data-title="cavavin">
                            <div className="item-img">
                                <img src={ImgCavavin} alt={t('cavavin.alt')} />
                                <Box display='flex' heigh='100%' flexDirection='column' className="item-img-overlay">
                                    <Box flexGrow={1} />
                                    <div>
                                        <h6>{t('categories.web')} . {t('categories.mobile')}</h6>
                                        <h5>{t('cavavin.title')}</h5>
                                        <p>{t('cavavin.content')}</p>
                                        <a href='https://d36uoosbtod3ou.cloudfront.net/cellar' target="_blank" rel="noopener noreferrer" className='link'>Visit (In progress)</a>
                                    </div>
                                    <Box flexGrow={1} />
                                </Box>
                            </div>
                        </div>

                    </div>
                </div>
            </ Box>
            </ThemeProvider>
        );
    }
}

export default withTranslation('portfolio')(PortfolioComp);