import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import i18next from "i18next";
import { initReactI18next, I18nextProvider } from "react-i18next";

import pages_fr from "./translations/fr/pages.json";
import pages_en from "./translations/en/pages.json";
import pages_cn from "./translations/cn/pages.json";

import home_fr from "./translations/fr/home.json";
import home_en from "./translations/en/home.json";
import home_cn from "./translations/cn/home.json";

import about_fr from "./translations/fr/about.json";
import about_en from "./translations/en/about.json";
import about_cn from "./translations/cn/about.json";

import portfolio_fr from "./translations/fr/portfolio.json";
import portfolio_en from "./translations/en/portfolio.json";
import portfolio_cn from "./translations/cn/portfolio.json";

import footer_fr from "./translations/fr/footer.json";
import footer_en from "./translations/en/footer.json";
import footer_cn from "./translations/cn/footer.json";

import services_fr from "./translations/fr/services.json";
import services_en from "./translations/en/services.json";
import services_cn from "./translations/cn/services.json";

import contact_fr from "./translations/fr/contact.json";
import contact_en from "./translations/en/contact.json";
import contact_cn from "./translations/cn/contact.json";

import cookies_fr from "./translations/fr/cookies.json";
import cookies_en from "./translations/en/cookies.json";
import cookies_cn from "./translations/cn/cookies.json";

let languageLocal = localStorage.getItem("language");
if (!languageLocal) languageLocal = 'fr';

i18next
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: {
                pages: pages_en,
                home: home_en,
                about: about_en,
                portfolio: portfolio_en,
                footer: footer_en,
                services: services_en,
                contact: contact_en,
                cookies: cookies_en,
            },
            cn: {
                pages: pages_cn,
                home: home_cn,
                about: about_cn,
                portfolio: portfolio_cn,
                footer: footer_cn,
                services: services_cn,
                contact: contact_cn,
                cookies: cookies_cn,
            },
            fr: {
                pages: pages_fr,
                home: home_fr,
                about: about_fr,
                portfolio: portfolio_fr,
                footer: footer_fr,
                services: services_fr,
                contact: contact_fr,
                cookies: cookies_fr,
            },
        },
        lng: languageLocal,
        fallbackLng: "en",

        interpolation: {
            escapeValue: false
        }
    });

ReactDOM.render(
    <I18nextProvider i18n={i18next}>
        <App />
    </I18nextProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
