import React from 'react';

// Import pages
import Home from './Home/Home'

// Import react router
import { Route, Switch } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import About from './About/About';
import Services from './Services/Services';
import Contact from './Contact/Contact';
import NotFound from './404/NotFound';

export const HomeRoute = "/";
export const AboutRoute = "/portfolio-projet-developpement-logiciel";
export const ServicesRoute = "/service-developpement-logiciel-grenoble";
export const ContactRoute = "/contact-developpeur-solution-sur-mesure-grenoble";

class Routing extends React.Component {
    render() {
        return (
            <Switch>
                <Route path={HomeRoute} exact component={Home} />
                <Route path={AboutRoute} exact component={About} />
                <Route path={ServicesRoute} exact component={Services} />
                <Route path={ContactRoute} exact component={Contact} />
                <Route component={NotFound} />
            </Switch>
        );
    }
}

export default withRouter(Routing);