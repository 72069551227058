import React from 'react';
import './struct/assets/css/bootstrap.min.css';
import { BrowserRouter as Router } from "react-router-dom";
//import { Sugar } from 'react-preloaders';

//import Amplify from 'aws-amplify';
//import awsconfig from './aws-exports';
import Routing from './struct/pages/Routing';
import ValidCookies from './struct/components/ValidCookies/ValidCookies';
import MainLayout from './struct/pages/Layout/MainLayout';

//Amplify.configure(awsconfig);

function App() {
  return (
    <React.Fragment>
      <Router>
        <MainLayout>
          <Routing />
        </MainLayout>
      </Router>
      {
        /*
        <Sugar color={'#00426A'} background="#fefefe" time={300} />
        */
      }
      
      <ValidCookies />
    </React.Fragment>

  );
}

export default App;